import * as React from "react"
import { Link } from "gatsby"

import menu from "../../files/menu.pdf"
import functions from "../../files/functions-events-guide.pdf"

// data

const links = [
  {
    title: "Menu",
    url: menu,
    internal: false,
  },
  {
    title: "Functions and Events",
    url: functions,
    internal: false,
  },
  {
    title: "Book a Table",
    url: "/book",
    internal: true,
  },
  {
    title: "Gift Vouchers",
    url: "https://giftcards.nowbookit.com/cards?accountid=e95c0f60-64e4-4a82-9341-1c004082f2bc&venueid=9729&theme=dark&accent=157,157,157",
    internal: false,
  },
]

const NavLink = props => {
  const data = props.data

  if (data.internal === true) {
    return (
      <Link
        to={data.url}
        className="inline-block font-bold uppercase tracking-wide text-sm py-2 px-3 bg-white text-dark hover:opacity-75 transition duration-200"
      >
        {data.title}
      </Link>
    )
  } else {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noreferrer"
        className="inline-block font-bold uppercase tracking-wide text-sm py-2 px-3 bg-white text-dark  hover:opacity-75 transition duration-200"
      >
        {data.title}
      </a>
    )
  }
}

const Navbar = data => {
  return (
    <nav className="relative py-8 w-full mx-auto">
      <ul className="flex flex-wrap justify-center items-center -m-1">
        {links.map((data, key) => {
          return (
            <li key={key} className="p-1">
              <NavLink data={data} />
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default Navbar
