import * as React from "react"

// markup
const Contact = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center">
      <div>
        <h2 className="text-sm uppercase font-bold mb-4">Open Hours</h2>
        <p>
          Monday & Tuesday - CLOSED
          <br />
          Wednesday & Thursday - Dinner from 5pm
          <br />
          Friday to Sunday - All day dining from 11:30am
        </p>
      </div>
      <div>
        <h2 className="text-sm uppercase font-bold mb-4">Location</h2>
        <p>
          Level 1, The Wharf Mooloolaba,
          <br />
          123 Parkyn Parade, Mooloolaba,
          <br /> Qld, 4557
        </p>
        <a
          href="https://www.google.com/maps/dir/The+Wharf+Mooloolaba,+123+Parkyn+Parade,+Mooloolaba+QLD+4557"
          className="text-xs underline"
          target="_blank"
          rel="noreferrer"
        >
          Directions
        </a>
      </div>
      <div>
        <h2 className="text-sm uppercase font-bold mb-4">Contact</h2>
        <p>
          <a href="tel:0732392116">07 3239 2116</a>
          <br />
          <a href="mailto:info@rbdining.com.au">info@rbdining.com.au</a>
        </p>
      </div>
    </div>
  )
}

export default Contact
