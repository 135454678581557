import * as React from "react"

import instagramIcon from "../../images/instagram.svg"
import facebookIcon from "../../images/facebook.svg"

const socials = [
  {
    title: "Instagram",
    url: "https://www.instagram.com/rbdining",
    icon: instagramIcon,
  },
  {
    title: "Facebook",
    url: "https://www.facebook.com/rbdiningmba",
    icon: facebookIcon,
  },
]

// markup
const Socials = () => {
  return (
    <ul className="flex justify-center items-center space-x-4">
      {socials.map((data, key) => {
        return (
          <li key={key}>
            <a
              href={data.url}
              target="_blank"
              rel="noreferrer"
              className="inline-blockhover:opacity-75"
              title={data.title}
            >
              <img src={data.icon} className="w-8 h-8" alt={data.title} />
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export default Socials
