import * as React from "react"
import logo from "../images/logo.svg"
import SEO from "../components/Seo"
import Navbar from "../components/Navbar"
import Socials from "../components/Socials"
import Contact from "../components/Contact"
import { StaticImage } from "gatsby-plugin-image"

import { Autoplay, EffectFade } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"

// markup
const IndexPage = () => {
  return (
    <>
      <SEO title="Home" pathname="/" description="" />
      <main className="relative min-h-screen flex flex-col">
        <div className="fixed">
          <Swiper
            className="inset-0 h-screen w-screen"
            speed={1000}
            modules={[Autoplay, EffectFade]}
            loop="true"
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            effect="fade"
            fadeEffect={{
              crossFade: true,
            }}
          >
            <SwiperSlide>
              <div className="bg-black absolute inset-0 flex items-center justify-center">
                <StaticImage
                  src="../images/img5.jpg"
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-black absolute inset-0 flex items-center justify-center">
                <StaticImage
                  src="../images/img4.jpg"
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-black absolute inset-0 flex items-center justify-center">
                <StaticImage
                  src="../images/img1.jpg"
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-black absolute inset-0 flex items-center justify-center">
                <StaticImage
                  src="../images/img2.jpg"
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-black absolute inset-0 flex items-center justify-center">
                <StaticImage
                  src="../images/img3.jpg"
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <Navbar />
        <section className="relative px-[8vw] w-full mx-auto py-32 flex justify-center items-center flex-1">
          <img src={logo} className="pointer-events-none h-40 " alt="RB Logo" />
        </section>
        <section className="relative mt-auto py-8 md:py-16 w-full max-w-5xl mx-auto px-4 space-y-8">
          <Socials />
          <Contact />
        </section>
      </main>
    </>
  )
}

export default IndexPage
